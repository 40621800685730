import React, { Component, PureComponent } from 'react'
import { ReactCSS as reactCSS } from 'reactcss'
import * as alpha from '../../helpers/alpha'

import Checkboard from './Checkboard'

export class Alpha extends (PureComponent || Component) {
  componentWillUnmount() {
    this.unbindEventListeners()
  }

  handleChange = (e) => {
    const change = alpha.calculateChange(e, this.props.hsl, this.props.direction, this.props.a, this.container)
    change && typeof this.props.onChange === 'function' && this.props.onChange(change, e)
  }

  handleMouseDown = (e) => {
    e.preventDefault();
    let focused = document.querySelector(':focus')
    if( focused ){
      focused.blur()
    }
    this.props.onDragStart()
    this.handleChange(e)
    window.addEventListener('mousemove', this.handleChange)
    window.addEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseUp = () => {
     this.props.onDragEnd()
    this.unbindEventListeners()
  }

  unbindEventListeners = () => {
    window.removeEventListener('mousemove', this.handleChange)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  render() {
    const rgb = this.props.rgb
    const styles = reactCSS({
      'vertical': {
        gradient: {
          background: `linear-gradient(to top, rgba(${ rgb.r },${ rgb.g },${ rgb.b }, 0) 0%,
           rgba(${ rgb.r },${ rgb.g },${ rgb.b }, 1) 100%)`,
        },
        pointer: {
          position: 'absolute',
          left: '-1px',
          top: `${ 100 - ( rgb.a * 100 ) }%`,
        },
      },
      'overwrite': {
        ...this.props.style,
      },
    }, {
      vertical: this.props.direction === 'vertical',
      overwrite: true,
    })

    return (
      <div className="alpha checkboard">
        <div className="gradient" style={ styles.gradient } />
        <div
          className="alpha-container"
          style={ styles.container }
          ref={ container => this.container = container }
          onMouseDown={ this.handleMouseDown }
          onTouchMove={ this.handleChange }
          onTouchStart={ this.handleChange }
        >
          <div className="pointer" style={ styles.pointer }>
              <div className="slider-handle" />
          </div>
        </div>
      </div>
    )
  }
}

export default Alpha
