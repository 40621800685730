export const calculateChange = (e, hsl, direction, initialA, container) => {
  const containerWidth = container.clientWidth
  const containerHeight = container.clientHeight
  const x = typeof e.pageX === 'number' ? e.pageX : e.touches[0].pageX
  const y = typeof e.pageY === 'number' ? e.pageY : e.touches[0].pageY
  const left = x - (container.getBoundingClientRect().left + window.pageXOffset)
  const top = y - (container.getBoundingClientRect().top + window.pageYOffset)

  if (direction === 'vertical') {
    let a

    if (0 >= top) {
      a = 1
    } else if (top >= containerHeight) {
      a = 0
    } else {
      a = Math.round((top * 100) / containerHeight) / 100;
      a = 1 - a;
    }

    if (hsl.a !== a) {
      return {
        h: hsl.h,
        s: hsl.s,
        l: hsl.l,
        a,
        source: 'rgb',
      }
    }
  } else {
    let a
    if (left < 0) {
      a = 1
    } else if (left > containerWidth) {
      a = 0
    } else {
      a = Math.round((left * 100) / containerWidth) / 100
    }

    if (initialA !== a) {
      return {
        h: hsl.h,
        s: hsl.s,
        l: hsl.l,
        a,
        source: 'rgb',
      }
    }
  }
  return null
}
