import React, { Component, PureComponent } from 'react'
import { ReactCSS as reactCSS } from 'reactcss'
import throttle from 'lodash/throttle'
import * as saturation from '../../helpers/saturation'

export class Saturation extends (PureComponent || Component) {
  constructor(props) {
    super(props)

    this.throttle = throttle((fn, data, e) => {
      fn(data, e)
    }, 50)

  }

  componentWillUnmount() {
    this.throttle.cancel()
    this.unbindEventListeners()
  }

  getContainerRenderWindow() {
    const { container } = this
    let renderWindow = window
    while (!renderWindow.document.contains(container) && renderWindow.parent !== renderWindow) {
      renderWindow = renderWindow.parent
    }
    return renderWindow
  }

  handleChange = (e) => {
    typeof this.props.onChange === 'function' && this.throttle(
      this.props.onChange,
      saturation.calculateChange(e, this.props.hsl, this.container),
      e,
    )
  }

  handleMouseDown = (e) => {
    e.preventDefault();
    let focused = document.querySelector(':focus')
    if( focused ){
      focused.blur()
    }
    this.props.onDragStart()
    this.handleChange(e)
    const renderWindow = this.getContainerRenderWindow()

    renderWindow.addEventListener('mousemove', this.handleChange)
    renderWindow.addEventListener('mouseup', this.handleMouseUp)

  }

  handleMouseUp = () => {
    this.unbindEventListeners()
  }

  unbindEventListeners() {
    
    const renderWindow = this.getContainerRenderWindow();

    this.props.onDragEnd()
    
    renderWindow.removeEventListener('mousemove', this.handleChange)
    renderWindow.removeEventListener('mouseup', this.handleMouseUp)
  }

  render() {
    const { color, white, black, pointer, circle } = this.props.style || {}
    const styles = reactCSS({
      'default': {
        color: {
          absolute: '0px 0px 0px 0px',
          background: `hsl(${ this.props.hsl.h },100%, 50%)`,
          borderRadius: this.props.radius,
        },
        white: {
          absolute: '0px 0px 0px 0px',
          borderRadius: this.props.radius,
        },
        black: {
          absolute: '0px 0px 0px 0px',
          boxShadow: this.props.shadow,
          borderRadius: this.props.radius,
        },
        pointer: {
          position: 'absolute',
          top: `${ -(this.props.hsv.v * 100) + 100 }%`,
          left: `${ this.props.hsv.s * 100 }%`,
          cursor: 'default',
        },
      },
      'custom': {
        color,
        white,
        black,
        pointer,
        circle,
      },
    }, { 'custom': !!this.props.style })

    return (
      <div
        style={ styles.color }
        ref={ container => this.container = container }
        onMouseDown={ this.handleMouseDown }
        onTouchMove={ this.handleChange }
        onTouchStart={ this.handleChange }
      >
        <style>{`
          .saturation-white {
            background: -webkit-linear-gradient(to right, #fff, rgba(255,255,255,0));
            background: linear-gradient(to right, #fff, rgba(255,255,255,0));
            outline-offset: -1px;
            outline: 1px solid rgba(170, 170, 170, 0.3);
          }
          .saturation-black {
            background: -webkit-linear-gradient(to top, #000, rgba(0,0,0,0));
            background: linear-gradient(to top, #000, rgba(0,0,0,0));
            outline-offset: -1px;
            outline: 1px solid rgba(170, 170, 170, 0.3);
          }
        `}</style>
        <div style={ styles.white } className="saturation-white">
          <div style={ styles.black } className="saturation-black" />
          <div style={ styles.pointer }>
            { this.props.pointer ? (
              <this.props.pointer { ...this.props } />
            ) : (
              <div className="saturation-dragger" />
            ) }
          </div>
        </div>
      </div>
    )
  }
}

export default Saturation
