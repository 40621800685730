import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FRONTEND_DATA } from "../../globals";
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";

import { Formik, Field, Form } from 'formik';

import { getRandomColor, getColorString } from "./helpers"

import { getCRDTItem, applyChangesToYType } from "../../lib/multi-user/redux";

import { MenuContext, ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";

import _ from 'lodash';

import { Button } from "@cargo/ui-kit";
import { CargoPicker, CustomPicker } from "../../lib/react-color";
import { Hue, Alpha, Saturation, EditableInput, Swatch } from "../../lib/react-color/components/common";

import RandomIcon from "@cargo/common/icons/randomize.svg";
import AddSwatchIcon from "@cargo/common/icons/add-swatch.svg";

import tinycolor from "tinycolor2";

import { globalUndoManager } from "../../lib/undo-redo";

import { 
    swatchSubscriptions, 
    initSwatchSubscriptions, 
    cancelSwatchSubscriptions, 
    saveNewColorSwatch,
    deleteColorSwatch
} from "../../lib/shared-css/swatch-subscriptions";

const ColorPickerModal = CustomPicker(class Interface extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hslColor: tinycolor( this.props.colorString ).toHsl(),
            hsvColor: tinycolor( this.props.colorString ).toHsv(),
            originalColor: null,
            colorString: null,
            colorArray: [],
            pseudoFocus: null, //For swatch focus outline when deleting
        };

        this.keydown          = false; 
        this.retainInputFocus = false;
        this.colorRemoved     = false;
        this.dragging         = false;

        this.valueOnInit = tinycolor( this.props.colorString );

        this.primaryInputRef = React.createRef();
    }

    handleInputChange = (value, event) => {

        if( !this.keydown && this.props.modalActive ){ return }

        let key = Object.keys(value)[0];
        value  = value[key];

        const newColorObj = tinycolor(value);

        if ( newColorObj.isValid() ){

            let format = newColorObj.getFormat()

            if( format !== this.props.colorFormat && 
                format !== 'string'               && 
                format !== 'name'                 &&
                this.keydown === true             &&
                ( event?.type == 'blur' || event?.type == 'keydown')
            ){
                // don't allow us to lock user format into string.
                // this.props.setPickerFormat( format );
            }
    
            this.setState({
                hslColor:newColorObj.toHsl()
            })

        }
    }

    handleAlphaChange = (newColor, e) => {
        // Handles alpha slider change, actual calculations are done in the sub component.
        this.setState((state, props) => {

            if ( !state.hslColor ){
                return {hslColor: newColor}
            } else {
                const hsl = {...state.hslColor};
                hsl.a = newColor.a;

                // let format = hsl.a < 1 ? 'rgb' : 'hex';

                return { hslColor: hsl } 
            }

        })  

    }

    handleSaturationChange = (newColor) => {
        newColor.s = newColor.s;
        newColor.v = newColor.v;
        // Handles saturation drag area changes, actual calculations are done in the sub component.

        const newColorObj = tinycolor(newColor);

        this.setState((state, props) => {

            const returnObj = {};
            
            if ( !state.hslColor ){
                returnObj.hslColor = newColorObj.toHsl();
            } else {
                let hsl = {...state.hslColor};
                let newHsl = newColorObj.toHsl();

                hsl.s = newHsl.s;
                hsl.l = newHsl.l;

                // If we change the saturation, but the color is transparent
                // instead of letting it remain transparent, we force alpha to 1
                if( hsl.a === 0 && newHsl.a == 0 ){
                    hsl.a = 1
                }

                returnObj.hslColor = hsl;
            }

            returnObj.hsvColor = newColor;

            return returnObj

        })  


    }

    handleHueChange = (newColor) => {
        // Handles hue slider change, actual calculations are done in the sub component.
        this.setState((state, props) => {
            if ( !state.hslColor ){
                return {hslColor: newColor}
            } else {
                let hsl = {...state.hslColor};
                
                hsl.h = newColor.h;

                return { hslColor: hsl } 
            }

        })  

    }

    handleSwatchChange = ( color ) => {

        if( this.props.isCustomSwatch ){ return }

        // clicking on a swatch can trigger the color picker input field blur event. We will
        // first apply the swatch here, but the change will be overwritten by `handleBlur` in
        // ../common/editableInput.js which fires on pointer up.
        setTimeout(() => {
             const newColorObj = tinycolor( color );
            // Handles clicking a swatch

            if ( newColorObj.isValid() ){
                this.hideSelectionColor();
                this.setState({hslColor:newColorObj.toHsl()})
            }
        })
       

    }

    addColorToSelectionPalette = (color) => {

        if( this.props.isCustomSwatch ){ return }

        let existingSwatches = swatchSubscriptions.get("subscribed-swatches").map(item => item.value);
        // Add a swatch to the selection palette
        let rgb = tinycolor(color).toHexString();

        if( color.a !== 1 ){
            rgb = tinycolor(color).toRgbString();
        }

        // No duplicates
        if( existingSwatches.indexOf(rgb) !== -1 ){
            return
        }

        saveNewColorSwatch(rgb); // save swatch as css variable

    }

    removeColorFromSelectionPalette = ( e, color ) => {

        const rgb = tinycolor(color).toRgbString();
        let currentRGB = tinycolor(this.state.hslColor).toRgbString();

        deleteColorSwatch(color);

        this.forceUpdate();

        if( this.props.isCustomSwatch && rgb === currentRGB){
            this.removeSelf();
        }
    }

    handleKeyDown = (event) => {
        if( event?.target.nodeName === 'INPUT' ){
            // We've typed in an input field, allows the color type ( HEX / RGBA ) to be locked.
            this.keydown = true
        }

        // If you're focused on a selection palette swatch, we want to remove it on "DELETE"
        if( event.keyCode === 8 && event.target.classList.contains('swatch') ){
            let color = event?.target.style.background;
            this.removeColorFromSelectionPalette( event, color );
        }

        // escape
        if ( event.keyCode === 27 ){
            if( document.activeElement.nodeName === 'INPUT' ){
                document.activeElement.blur()
            }
            // Set the original color back / cancel all changes
            let newColorObj = tinycolor( this.state.originalColor );
            this.setState({hslColor:newColorObj.toHsl()});
            // close the modal
            // this.props.closeModal(null);
        }

        // enter / return 
        // Enter should be handled by input field
        if ( event.keyCode === 13 ) {
            if( document.activeElement.nodeName === 'INPUT' ){
                // document.activeElement.blur();
            }
            // this.props.closeModal(event);
        }
    }

    randomizeColor = () => {
        let newColorObj = getRandomColor( this.state.hslColor );
        this.hideSelectionColor();
        this.debounceShowSelectionColor();

        // Blur before we set the new color
        // to prevent blur on input from making a change as well.
        document.activeElement.blur();

        if ( newColorObj.isValid()){
            this.setState({hslColor:newColorObj.toHsl()})
        }
    }

    showBorder = (color) => {
        // If swatch color is transparent, we don't want to show a border.
        if( !color ){ return false }
        let alpha = tinycolor(color).getAlpha();
        let showBorder = alpha > 0;
        return showBorder
    }

    trackFocus = (e) => {

        if( e.target.classList.contains('input-field') ){
            this.retainInputFocus = true;
            return
        }  

        if( ( e.target.closest('.slider') !== null ||
              e.target.closest('.saturation') !== null || 
              e.target.closest('.last') !== null ) &&
            this.retainInputFocus 
        ){
            this.focusColorInput();
            return
        }

        this.retainInputFocus = false;
    }

    // Highlights a swatch
    handlePseudoFocus = ( color ) => {
        this.setState({pseudoFocus: color ? color : null })
    }

    focusColorInput = () => {
        let inputField = this.primaryInputRef.current.input;    

        let inputLength = inputField.value.length
        if(inputField !== undefined && inputLength !== undefined ){
            inputField.focus();
            inputField.setSelectionRange(inputLength, inputLength+1)
        }
    }

    render(){

        const newColorObj  = tinycolor(this.state.hslColor);
        const hsl = {...this.state.hslColor};
        const hsv = {...this.state.hsvColor};

        // prevent hue creeping in hsv > hsl conversion
        hsv.h = hsl.h;

        // alpha slider uses rgba
        const rgb = newColorObj.toRgb();
        const rgbaString = newColorObj.toRgbString();
        const hex = newColorObj.toHexString();

        let primaryInputValue = hsl.a < 1 ? rgbaString : hex;

        if( this.props.colorFormat ){
            primaryInputValue = getColorString( newColorObj, this.props.colorFormat )
        }

        // Hide primary input value text if color is fully transparent.
        let transparent = false;
        if( hsl.h == 0 &&
            hsl.s == 0 &&
            hsl.l == 0 &&
            hsl.a == 0 ){
            transparent = true;
        }
        // Swatch related 
        const swatches = swatchSubscriptions.get("subscribed-swatches").map(item => item.value);
        const SwatchContextMenuUI = ( color ) => {
            let col = color?.color?.color;
            return( 
                <>
                    <ContextMenuButton 
                        label="Delete" 
                        onPointerUp = { (e) => { this.removeColorFromSelectionPalette( e, col ) } }
                    />  
                </>

            )
        }

        return(
            <div className={`color-picker${this.props.tabbed ? ' tabbed' : '' }${this.props.alpha === false ? ' alpha-disabled' : '' }`} >
                <div className={`cargo-picker-background${this.props.tabbed ? ' tabbed' : '' }`}>
                    <div 
                        className="cargo-picker"
                        onClick={ e => this.trackFocus(e) }
                    >
                        <div className="main-controls disable-drag">
                            <div className="saturation" >
                                <Saturation
                                    className   = "drag-area"
                                    hsl         = { hsl }
                                    hsv         = { hsv }
                                    onChange    = { this.handleSaturationChange }
                                    onDragStart = { this.onUIDragStart }
                                    onDragEnd   = { this.onUIDragEnd }
                                />
                            </div>
                            <div className="slider-area">
                                <div className="slider">
                                    <Hue
                                        className   = "hue control"
                                        hsl         = { hsl }
                                        direction   = 'vertical'
                                        onChange    = { this.handleHueChange }
                                        onDragStart = { this.onUIDragStart }
                                        onDragEnd   = { this.onUIDragEnd }
                                    />
                                </div>
                                {this.props.alpha !== false ? (

                                    <div className="slider">
                                        <Alpha
                                            className   = "alpha control"
                                            rgb         = { rgb }
                                            hsl         = { hsl }
                                            direction   = 'vertical'
                                            onChange    = { this.handleAlphaChange }
                                            onDragStart = { this.onUIDragStart }
                                            onDragEnd   = { this.onUIDragEnd }
                                        />
                                    </div> 

                                ):(null)}
                            </div>
                        </div>
                        <div className={`input-field primary disable-drag`}>
                            <EditableInput
                                label          = "color string"
                                value          = { primaryInputValue }
                                onKeyDown      = { this.handleKeyDown }
                                onChange       = { this.handleInputChange }
                                updateOnAction = { true }  // Only update on blur or enter 
                                focusOnInit    = { true }  // focus as soon as input is rendered
                                ref            = { this.primaryInputRef }
                                isTransparent  = { transparent }
                            />
                        </div>
                        <div className="palette-row">

                            <div>
                                <div className={`palette-swatch disable-drag checkboard${this.showBorder(this.state.originalColor) ? '' : ' transparent'}`}>
                                    <div 
                                        className     = "swatch" 
                                        style         = {{ backgroundColor: `${ this.state.originalColor }` }}
                                        onMouseDown = { (e) => this.handleSwatchChange( this.state.originalColor, e ) }
                                    />
                                </div>
                            </div>

                            <div className="second">
                                <div className={`palette-swatch disable-drag checkboard${this.showBorder(this.state.colorString) ? '' : ' transparent'}`}>
                                    <div 
                                        className     = "swatch" 
                                        style         = {{ backgroundColor: `${this.state.colorString}` }} 
                                        onMouseDown = { (e) => this.handleSwatchChange( this.state.colorString, e ) }
                                    />
                                </div>
                            </div>

                            <div className="last">
                                <div className={`palette-swatch disable-drag checkboard`}>
                                    <div 
                                        className     = "swatch" 
                                        style         = {{ backgroundColor: `rgba(0,0,0,0)` }} 
                                        onMouseDown   = {(e) => { 
                                            this.retainInputFocus = false;
                                            if( transparent ){ return }

                                            const newColorObj = tinycolor( 'rgba(0,0,0,0)' );
                                            // Handles clicking a swatch
                                            if ( newColorObj.isValid() ){
                                                this.setState({hslColor:newColorObj.toHsl()})
                                            }

                                            this.setState({
                                                hslColor : newColorObj.toHsl()
                                            }, ()=>{
                                                this.primaryInputRef.current.input.value = 'rgba(0,0,0,0)';
                                                this.props.removeRule( this.props.name );
                                                this.colorRemoved = true;
                                            })

                                        }}
                                    />
                                </div>
                            </div> 

                            <div 
                                className = "randomize disable-drag" 
                                onMouseDown   = { ()=> this.randomizeColor() } 
                            >
                                <RandomIcon />
                            </div>

                        </div>

                        {/* User Swatches */}
                        <div className="selection-palette">

                           {/* { !this.props.isCustomSwatch ? (  */}
                                <Button
                                    className = "add-swatch" 
                                    onMouseDown = { ()=> this.addColorToSelectionPalette( this.state.hslColor ) }
                                    label     = { <AddSwatchIcon /> } 
                                />
                            {/* ) : ( null )}  */}

                            {/* Loop through user swatches for render... */}
                            { swatches.map((colorObjOrString) => {
                                const c = typeof colorObjOrString === 'string' ? { color: colorObjOrString } : colorObjOrString
                                const key = `${c.color}${c.title || ''}`

                                const newColorObj = tinycolor( this.state.hslColor );

                                let originalColorStringHex = newColorObj.toHexString();
                                let originalColorStringRGBA = newColorObj.toRgbString();
                                let originalColorSpaceless = originalColorStringRGBA.replace(/ /g, '');
                                // Determine outline / focus automatically
                                let isCurrentColor = originalColorStringRGBA == c.color || originalColorSpaceless == c.color || originalColorStringHex == c.color;

                                let pseudoFocus = false;

                                if( this.state.pseudoFocus === colorObjOrString ){
                                    pseudoFocus = true;
                                }

                                let focusStyles = { outline: `#ff0000 1px solid`, outlineOffset: '1px' }

                                return (
                                    <React.Fragment key={ key }>
                                        <MenuContext.Consumer>
                                            {(Menu) => 

                                                <div
                                                    // key={ key } 
                                                    className={`user-swatch disable-drag${isCurrentColor ? ' focus' : ''}`}
                                                    onContextMenu = { e => {

                                                            e.preventDefault();

                                                            this.handlePseudoFocus( colorObjOrString )

                                                            Menu.openMenu({
                                                                innerUI: <SwatchContextMenuUI color={ c }/>,
                                                                type: 'button',
                                                                event: e,
                                                                onClose: this.handlePseudoFocus
                                                            })

                                            
                                                        }
                                                    }
                                                >
                                                    <Swatch
                                                        { ...c }
                                                        onMouseDown = { (color, event) => { 
                                                            if (event?.nativeEvent?.which === 3) {
                                                                return;
                                                            }
                                                            this.handleSwatchChange( colorObjOrString ) 
                                                        }}
                                                        focusStyle    = { focusStyles }
                                                        pseudoFocus   = { pseudoFocus }
                                                    />
                                                </div>
                                            }
                                        </MenuContext.Consumer>
                                    </React.Fragment>
                                )

                            }) }

                        </div>
                        {/* End user Swatches */}

                    </div>

                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {

        this.lastHslColor = prevState.hslColor;

        if ( !prevState.hslColor ){

            let colorObj = tinycolor( this.state.hslColor )

            if( this.props.updateFieldOnChange && !this.undoing ){
                this.props.updateFieldOnChange( colorObj, this.props.name, this.props.form );   
            }

            return

        } else if ( 
            !tinycolor.equals( this.state.hslColor, prevState.hslColor ) 
        ) {

            let colorObj = tinycolor( this.state.hslColor );

            if( this.props.updateFieldOnChange && !this.undoing ){
                this.props.updateFieldOnChange(colorObj, this.props.name, this.props.form);
            } 

            // Ensure Hue gets carried through change change even when it has no effect on
            // actual displayed color. This prevents UI from getting locked up when making
            // changes to the hue slider that would otherwise have no visible / real
            // effect on the displayed color or rgba / hex value. 
            let toHSL = colorObj.toHsl()
            let toHSV = colorObj.toHsv()

            // While dragging the saturation picker we always
            // want to use the saturation returned by the picker itself. 
            // Tinycolor can discard saturation values when it hits 0 for 
            // the hue and lightness components making the cursor jump
            if(this.dragging) {
                toHSV.s = this.state.hsvColor.s;
            }

            // If hue fed into the conversion as object !== hue in formatted value
            // AND hue is a number ( not some rgba value )
            // AND the hue is being reset to 0
            if( colorObj._originalInput.h !== toHSL.h && 
                !isNaN(colorObj._originalInput.h) && 
                toHSL.h == 0 
            ){
                toHSL.h = colorObj._originalInput.h
                toHSV.h = colorObj._originalInput.h
            }

            let colorString

            if( this.state.colorFormat ){

                // use helper function to determine formatting based off format string
                colorString = getColorString(colorObj, this.state.colorFormat );

            } else {

                // Otherwise decide between RGBA or HEX dependant on opacity
                colorString = colorObj._a === 1 ? colorObj.toHexString() : colorObj.toRgbString();

            }

            if( this.state.hslColor.a !== 0  ){

                this.colorRemoved = false;

                this.setState({
                    hslColor : toHSL,
                    hsvColor : toHSV,
                    colorString: colorString
                })
            }

            return
        }

        // Picker modal reacts to external changes from updateUIWindow pushes
        if( this.props.name && this.props.form.values && this.props.form.values[this.props.name[0]] ){
            let selector = this.props.name[0];
            let property = this.props.name[1];

            let color = this.props.form.values[selector][property];
            let prevColor = prevProps.form?.values?.[selector]?.[property];

            // We've got a new color, not from within the picker.
            if( color !== prevColor ){

                const newColorObj = tinycolor( color );
                let originalColorString = newColorObj._a === 1 ? newColorObj.toHexString() : newColorObj.toRgbString();

                this.retainInputFocus = false;
                // Blur input so we can update text
                if( document.activeElement.nodeName === 'INPUT' ){
                    document.activeElement.blur()
                }
                // Set state
                this.setState({
                    // hslColor : newColorObj.toHsl(),
                    // hsvColor : newColorObj.toHsv(),
                    colorString: originalColorString,
                    originalColor : originalColorString
                })
            }
        }


    };

    onUIDragStart = () => {

        this.dragging = true;

        // pause undo/redo reporting while dragging
        globalUndoManager.pause();

        this.hideSelectionColor();

        // Prevent clickout / window closure
        window.store.dispatch({
            type: 'UPDATE_ADMIN_STATE', 
            payload: {
                pauseGlobalEventExecution: false
            }
        }); 

        // Disable pointer evnts on iframe container and doc body,
        // this is the easiest way to stop click propagation and iframe from swallowing events for now
        document.body.style.pointerEvents = 'none';
        document.getElementById('device-viewport').style.pointerEvents = 'none'
    }

    onUIDragEnd = () => {

        this.dragging = false;

        // only report final value
        globalUndoManager.resume();

        this.showSelectionColor();

        // Allow clickout again
        window.store.dispatch({
            type: 'UPDATE_ADMIN_STATE', 
            payload: {
                pauseGlobalEventExecution: false
            }
        }); 

        // Re-enable pointer events
        document.body.style.pointerEvents = '';
        document.getElementById('device-viewport').style.pointerEvents = ''
    }

    hideSelectionColor = () => {

        const frontendDoc = FRONTEND_DATA.contentWindow.document;

        if(!frontendDoc.body.querySelector('#temp-sp-styles')){
            const selectionRule = '::selection { background:transparent !important;} body { --selection-color:transparent!important; }';
            const styleNode = document.createElement('style');
            styleNode.innerHTML = selectionRule;
            frontendDoc.body.appendChild(styleNode);
            styleNode.id = 'temp-sp-styles';
        }

    }

    showSelectionColor = () => {

        const frontendDoc = FRONTEND_DATA.contentWindow.document;

        if(frontendDoc.body.querySelector('#temp-sp-styles')){
            frontendDoc.body.querySelector('#temp-sp-styles').remove();
        }
    }

    debounceShowSelectionColor = _.debounce(()=> { 

        const frontendDoc = FRONTEND_DATA.contentWindow.document;

        if(frontendDoc.body.querySelector('#temp-sp-styles')){
            frontendDoc.body.querySelector('#temp-sp-styles').remove();
        }

    }, 2000, false)

    handleInputChangeBeforeUnmount = () => {

        let value = this.primaryInputRef.current.input.value;

        if( value.length === 0 || this.colorRemoved ){ return }

        const newColorObj = tinycolor(value);

        if( this.valueOnInit._a === 0 && newColorObj._a === 0 ){
            // We opened this color picker with a transparent value
            // We are closing the picker with a transparent value
            // No change should be made.
            return
        }

        if ( newColorObj.isValid() && 
             newColorObj.toHsl() !== this.state.hslColor
        ){
            if( this.props.updateFieldOnChange ){
                this.props.updateFieldOnChange( newColorObj, this.props.name, this.props.form );   
            }
            
        }
    }

    componentDidMount(){

        initSwatchSubscriptions(this.props.stylesheet);

        // Set color in picker from props 
        const newColorObj = tinycolor( this.props.colorString );
        let originalColorString = newColorObj._a === 1 ? newColorObj.toHexString() : newColorObj.toRgbString();

        this.setState({
            hslColor : newColorObj.toHsl(),
            hsvColor : newColorObj.toHsv(),
            colorString: originalColorString,
            originalColor : originalColorString
        })

        // Set keydown flag to false since we've just opened the picker and the input will be auto focused.
        this.keydown = false;
        // Start tracking user focus...
        this.retainInputFocus = true;

        globalUndoManager.on('yjs-stackitem-start', this.onYJSUndoStackItemStarted);
        globalUndoManager.on('yjs-stackitem-finalized', this.onYJSUndoStackItemFinalized);
        globalUndoManager.on('yjs-stackitem-applied', this.onYJSUndoStackItemApplied);

        window.addEventListener("keydown", this.handleKeyDown);
        // Hex if alpha is 1, else RGBA
    };

    componentWillUnmount(){
        this.showSelectionColor();
        this.handleInputChangeBeforeUnmount();
        // remove keydown listener
        window.removeEventListener("keydown", this.handleKeyDown);

        globalUndoManager.off('yjs-stackitem-start', this.onYJSUndoStackItemStarted);
        globalUndoManager.off('yjs-stackitem-finalized', this.onYJSUndoStackItemFinalized);
        globalUndoManager.off('yjs-stackitem-applied', this.onYJSUndoStackItemApplied);

        cancelSwatchSubscriptions();
    }

    onYJSUndoStackItemStarted = (stackItem) => {

        if(!stackItem.meta.has(this)) {
            stackItem.meta.set(this, {})
        }

        if (stackItem.meta.get(this)['undo-color'] === undefined) {
            stackItem.meta.get(this)['undo-color'] = this.lastHslColor;
        }

    }

    onYJSUndoStackItemFinalized = (stackItem) => {
        
        if(!stackItem.meta.has(this)) {
            stackItem.meta.set(this, {})
        }

        if (stackItem.meta.get(this)['redo-color'] === undefined) {
            stackItem.meta.get(this)['redo-color'] = this.lastHslColor;
        }

    }

    onYJSUndoStackItemApplied = (stackItem, actionType) => {

        const oldColor = actionType === 'undo' ? stackItem.meta.get(this)['undo-color'] : stackItem.meta.get(this)['redo-color'];

        if(oldColor) {

            this.undoing = true;

            this.setState({
                hslColor: oldColor
            }, () => {
                setTimeout(() => {
                    this.undoing = false;
                })
            })
        }

    }

    removeSelf = () => {
        // this may not work in all instances. Specific color picker instances
        // can have unique id names.
        this.props.removeUIWindow(uiWindow => {
            return uiWindow.id === 'color-palette';
        })
    }


});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        removeUIWindow: actions.removeUIWindow
    }, dispatch);
}

function mapReduxStateToProps(state, ownProps) {

    return {
        adminStateCRDT: state.adminState.crdt,
        stylsheet: state.css.stylesheet
    };

}


export default connect(
    mapReduxStateToProps, 
    mapDispatchToProps
)(ColorPickerModal); 