import React from 'react'
import PropTypes from 'prop-types'
import { ReactCSS as reactCSS } from 'reactcss'

import { Swatch } from '../common'

export const CargoPresetColors = ({ colors, onClick = () => {}, onSwatchHover }) => {
  const styles = reactCSS({
    'no-presets': {
      colors: {
        display: 'none',
      },
    },
  }, {
    'no-presets': !colors || !colors.length,
  })

  const handleClick = (hex, e) => {
    onClick({
      hex,
      source: 'hex',
    }, e)
  }

  return (
    <div className="user-swatches">
      { colors.map((colorObjOrString) => {
        const c = typeof colorObjOrString === 'string'
          ? { color: colorObjOrString }
          : colorObjOrString
        const key = `${c.color}${c.title || ''}`
        return (
          <div key={ key } className="user-swatch">
            <Swatch
              { ...c }
              onClick={ handleClick }
              onHover={ onSwatchHover }
              focusStyle={{
                outline: `#698fff 1px solid`,
                outlineOffset: '1px'
              }}
            />
          </div>
        )
      }) }
    </div>
  )
}

CargoPresetColors.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      color: PropTypes.string,
      title: PropTypes.string,
    })],
  )).isRequired,
}

export default CargoPresetColors
