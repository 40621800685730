/* eslint-disable no-param-reassign */

import React from 'react'
import { ReactCSS as reactCSS } from 'reactcss'
import * as color from '../../helpers/color'

import { EditableInput } from '../common'

export const CargoFields = ({ onChange, rgb, hsl, hex, disableAlpha }) => {
  const styles = reactCSS({
    'disableAlpha': {
      alpha: {
        display: 'none',
      },
    },
  }, { disableAlpha })

  const handleChange = (data, e) => {
    if (data.hex) {
      color.isValidHex(data.hex) && onChange({
        hex: data.hex,
        source: 'hex',
      }, e)
    } else if (data.r || data.g || data.b) {
      onChange({
        r: data.r || rgb.r,
        g: data.g || rgb.g,
        b: data.b || rgb.b,
        a: rgb.a,
        source: 'rgb',
      }, e)
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0
      } else if (data.a > 100) {
        data.a = 100
      }

      data.a /= 100
      onChange({
        h: hsl.h,
        s: hsl.s,
        l: hsl.l,
        a: data.a,
        source: 'rgb',
      }, e)
    }
  }

  return (
    <div className="input-fields">
      <div style={ styles.double }>
        <EditableInput
          label="hex"
          value={ hex.replace('#', '') }
          onChange={ handleChange }
        />
      </div>
      <div style={ styles.single }>
        <EditableInput
          label="r"
          value={ rgb.r }
          onChange={ handleChange }
          dragLabel="true"
          dragMax="255"
        />
      </div>
      <div style={ styles.single }>
        <EditableInput
          label="g"
          value={ rgb.g }
          onChange={ handleChange }
          dragLabel="true"
          dragMax="255"
        />
      </div>
      <div style={ styles.single }>
        <EditableInput
          label="b"
          value={ rgb.b }
          onChange={ handleChange }
          dragLabel="true"
          dragMax="255"
        />
      </div>
      <div style={ styles.alpha }>
        <EditableInput
          label="a"
          value={ Math.round(rgb.a * 100) }
          onChange={ handleChange }
          dragLabel="true"
          dragMax="100"
        />
      </div>
    </div>
  )
}

export default CargoFields
