import React from 'react'
import PropTypes from 'prop-types'
import { ReactCSS as reactCSS } from 'reactcss'
import merge from 'lodash/merge'

import { ColorWrap, Saturation, Hue, Alpha, Checkboard } from '../common'
import CargoFields from './CargoFields'
import CargoPresetColors from './CargoPresetColors'

export const Cargo = ({ width, rgb, hex, hsv, hsl, onChange, onSwatchHover,
  disableAlpha, presetColors, renderers, styles: passedStyles = {}, className = '' }) => {
  const styles = reactCSS(merge({
    'default': {
      activeColor: {
        background: `rgba(${ rgb.r },${ rgb.g },${ rgb.b },${ rgb.a })`,
      },
      transparent: {
        background: `transparent`,
      },
      ...passedStyles,
    },
    'disableAlpha': {
      color: {
        height: '10px',
      },
      hue: {
        height: '10px',
      },
      alpha: {
        display: 'none',
      },
    },
  }, passedStyles), { disableAlpha })

  return (
    <div className={ `cargo-picker` }>
      <div className={`main-controls`} >
        <div className={'saturation'} >
          <Saturation
            className="drag-area"
            hsl={ hsl }
            hsv={ hsv }
            onChange={ onChange }
          />
        </div>
        <div className={`slider-area`}>
          <div className={`slider`} >
            <Hue
              className={`hue control`}
              hsl={ hsl }
              direction='vertical'
              onChange={ onChange }
            />
          </div>
          <div className={`slider`}>
            <Alpha
              className={`alpha control`}
              rgb={ rgb }
              hsl={ hsl }
              direction='vertical'
              renderers={ renderers }
              onChange={ onChange }
            />
          </div>  
        </div>
      </div>
      <CargoFields
        rgb={ rgb }
        hsl={ hsl }
        hex={ hex }
        onChange={ onChange }
        disableAlpha={ disableAlpha }
      />
      <div className={ `palette-row` }>
        <div>
          <div className={`palette-swatch`}>
            <Checkboard />
            <div className={`swatch`} style={ styles.activeColor } />
          </div>
        </div>
        <div className={ `second` }>
          <div className={`palette-swatch`}>
            <Checkboard />
            <div className={`swatch`} style={ styles.activeColor } />
          </div>
        </div>
        <div className={ `last` }>
          <div className={`palette-swatch`}>
            <Checkboard />
            <div className={`swatch`} style={ styles.transparent } />
          </div>
        </div> 
      </div>
      <CargoPresetColors
        colors={ presetColors }
        onClick={ onChange }
        onSwatchHover={ onSwatchHover }
      />
    </div>
  )
}

Cargo.propTypes = {
  disableAlpha: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.object,
}

Cargo.defaultProps = {
  disableAlpha: false,
  width: 224,
  styles: {},
  presetColors: ['#4A90E2', '#50E3C2', '#B8E986', '#000000',
    '#4A4A4A', '#9B9B9B', '#FFFFFF'],
}

export default ColorWrap(Cargo)
