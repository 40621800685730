import React, { Component, PureComponent } from 'react'
import { ReactCSS as reactCSS } from 'reactcss'
import * as hue from '../../helpers/hue'

export class Hue extends (PureComponent || Component) {
  componentWillUnmount() {
    this.unbindEventListeners()
  }

  handleChange = (e) => {
    const change = hue.calculateChange(e, this.props.direction, this.props.hsl, this.container)
    // console.log("CHANGE", change )
    change && typeof this.props.onChange === 'function' && this.props.onChange(change, e)
  }

  handleMouseDown = (e) => {
    e.preventDefault();
    let focused = document.querySelector(':focus')
    if( focused ){
      focused.blur()
    }
    this.props.onDragStart()
    this.handleChange(e)
    window.addEventListener('mousemove', this.handleChange)
    window.addEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseUp = () => {
    this.props.onDragEnd()
    this.unbindEventListeners()
  }

  unbindEventListeners() {
    window.removeEventListener('mousemove', this.handleChange)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  render() {
    const { direction = 'horizontal' } = this.props

    const styles = reactCSS({
      'vertical': {
        pointer: {
          position: 'absolute',
          left: '-1px',
          top: `${ ( (this.props.hsl.h * 100) / 360) }%`,
          cursor: 'default',
        },
      },
    }, { vertical: direction === 'vertical' })
    // console.log("HUE", this.props.hsl )
    return (
      <div className="hue">
        <div
          className={ `hue-vertical` }
          ref={ container => this.container = container }
          onMouseDown={ this.handleMouseDown }
          onTouchMove={ this.handleChange }
          onTouchStart={ this.handleChange }
        >
          <div className="pointer" style={ styles.pointer }>
              <div className="slider-handle" />
          </div>
        </div>
      </div>
    )
  }
}

export default Hue
