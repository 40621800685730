import getGlobalCSSParser from "./get-global-parser";

import _ from 'lodash';

export const swatchSubscriptions = new Map();

swatchSubscriptions.set("subscribed-swatches", []);

const updateSubscriptions = evt => {
    // console.log(evt, "< < < subscription event")

    const { property: name, type, value } = evt;

    const subscribedSwatches = swatchSubscriptions.get("subscribed-swatches");

    if(type === "added"){
        const updatedSwatches = [...subscribedSwatches, {
            name, 
            value: value
        }]
        swatchSubscriptions.set("subscribed-swatches", updatedSwatches);
        } else if(type === "removed"){
        swatchSubscriptions.set("subscribed-swatches", subscribedSwatches.filter(subscribed => subscribed.name !== name))
        // console.log("Updated subscriptions: ", swatchSubscriptions.get("subscribed-swatches"));
    } else if(type === "updated"){
        const updatedSwatches = subscribedSwatches.map(swatch => {
            if(swatch.name === name){
                return {
                    ...swatch,
                    value: value
                }
            } else return swatch
        });

        swatchSubscriptions.set("subscribed-swatches", updatedSwatches);
        // console.log("Updated swatches: ", swatchSubscriptions.get("subscribed-swatches"));
    }
}

export const cancelSwatchSubscriptions = () => {

    // clear subscriptions
    swatchSubscriptions.get("parser")?.unsubscribe(updateSubscriptions);

    // clear swatches
    swatchSubscriptions.set("subscribed-swatches", []);

}


export const initSwatchSubscriptions = (stylesheet) => {

    const parser = getGlobalCSSParser(stylesheet);

    // subscribe to all declarations that include '--swatch'
    parser.subscribe(property => property.includes('--swatch'), updateSubscriptions);
    swatchSubscriptions.set("parser", parser);
    parser.parseCSS();

}

export const saveNewColorSwatch = (color) => {
    
    const parser = swatchSubscriptions.get("parser");

    // first iterate over current subscriptions to find a name
    const subscribedSwatches = swatchSubscriptions.get("subscribed-swatches");

    const names = subscribedSwatches.map(item => item.name);
    // determine a unique name for the swatch - defaults to --swatch-1
    const newName = determineSwatchName(names);
    console.log("new swatch", newName, color )
    // insert the swatch into the css in the body tag
    parser.insertNewColorSwatch(color, newName, "body");

    // reset subscriptions so we get the correct swatch order
    cancelSwatchSubscriptions();
    initSwatchSubscriptions();

}

export const deleteColorSwatch = (color) => {

    const parser = swatchSubscriptions.get("parser");

    // first iterate over current subscriptions to find a name
    const subscribedSwatches = swatchSubscriptions.get("subscribed-swatches");

    let swatchToRemove = null;
    _.each( subscribedSwatches, (swatch) => {
        if( color === swatch.value){
            swatchToRemove = swatch;
        }
    })

    if( swatchToRemove ){
        parser.getPropWatcher("body", swatchToRemove.name)?.setValue(null);
        // parser.removeColorSwatch(swatchToRemove.value, swatchToRemove.name, "body");
    }

}

const determineSwatchName = (names, counter = 1) => {
    const newName = `--swatch-${counter}`;
    if(names.includes(newName) === true){
        return determineSwatchName(names, counter + 1);
    } else return newName;
}

