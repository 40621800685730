import React, { Component, PureComponent } from 'react'
import { ReactCSS as reactCSS } from 'reactcss'

const DEFAULT_ARROW_OFFSET = 1

const UP_KEY_CODE = 38
const DOWN_KEY_CODE = 40
const VALID_KEY_CODES = [
  UP_KEY_CODE,
  DOWN_KEY_CODE
]
const isValidKeyCode = keyCode => VALID_KEY_CODES.indexOf(keyCode) > -1
const getNumberValue = value => Number(String(value).replace(/%/g, ''))

let idCounter = 1

export class EditableInput extends (PureComponent || Component) {
  constructor(props) {
    super()

    this.state = {
      value:  this?.props?.isTransparent || props?.isTransparent ? '' : String(props.value).toUpperCase(),
      blurValue: String(props.value).toUpperCase(),
    }

    this.inputId = `rc-editable-input-${idCounter++}`
    this.ignoreNext = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.value !== this.state.value &&
      (prevProps.value !== this.props.value || prevState.value !== this.state.value)
    ) {

      // Clearing the input field on transparent.
      if( this.ignoreNext ){
        this.ignoreNext = false;
        return
      }

      if (this.input === document.activeElement) {
        let value = String(this.props.value).toUpperCase().replace(/\s/g, '');
        this.setState({ blurValue: value })
      } else {
         let value = String(this.props.value).toUpperCase();
         if( value ){
          value = value.replace(/\s/g, '');
         }
        this.setState({ value: value, blurValue: !this.state.blurValue && value })
      }
    }

    if( prevProps.isTransparent === false 
      && this.props.isTransparent === true ){
      this.ignoreNext = true;
      this.setState({value: '', blurValue: 'rgba(0,0,0,0)'})
    }

  }

  componentWillUnmount() {
    this.unbindEventListeners()
  }

  componentDidMount() {
    if( this.props.focusOnInit ){
      setTimeout(() => { 
        if( this.input ){
          this.input.focus() 
        }
      }, 1)
    }
  }

  getValueObjectWithLabel(value) {
    return {
      [this.props.label]: value
    }
  }

  handleBlur = (e) => {
    if (this.state.blurValue) {
      this.setState({ value: this.state.blurValue, blurValue: null })

      this.setState({ value: this.state.blurValue })
      let onChangeValue = this.props.label ? this.getValueObjectWithLabel(this.state.value) : this.state.value

      this.props.onChange && this.props.onChange( onChangeValue, e)
    }
  }

  handleChange = (e) => {

    if( this.props.type == 'alpha' ){
      let value = e.target.value.replace(/\%/, '')
      if( value == '' && this.props.type == 'alpha' ){
        value = 0;
      }
      value = parseInt( value );
      this.setUpdatedValue(value, e)
      return 
    }

    this.setUpdatedValue(e.target.value, e)
    
  }

  getArrowOffset() {
    return this.props.arrowOffset || DEFAULT_ARROW_OFFSET
  }

  handleClick = (e) => {
    if( this.props.type == 'alpha' || this.props.type == 'hex field' ){
      this.input.select()
    }
  }

  handleKeyDown = (e) => {
    // In case `e.target.value` is a percentage remove the `%` character
    // and update accordingly with a percentage
    // https://github.com/casesandberg/react-color/issues/383
    let value

    if( e.keyCode === 8 && this.props.type == 'alpha' ){
      value = parseInt( e.target.value.replace(/\%/, '') );
      let valArray  = value.toString().split('');
        valArray.pop();
        valArray = valArray.join('')
        value = parseInt( valArray );
        value = getNumberValue( value )

        if( !isNaN(value) ){
          this.setUpdatedValue(value, e)
        }        
        return
    }

    value = getNumberValue( e.target.value )

    if (!isNaN(value) && isValidKeyCode(e.keyCode)) {
      const offset = this.getArrowOffset()
      const updatedValue = e.keyCode === UP_KEY_CODE ? value + offset : value - offset
      if( this.props.type == 'alpha' && updatedValue > 100 || this.props.type == 'alpha' && updatedValue < 0 ){
        return
      }
      this.setUpdatedValue(updatedValue, e)
    }
    // handle enter
    if( e.keyCode === 13 ){
      let onChangeValue = this.props.label ? this.getValueObjectWithLabel(this.state.value) : this.state.value
      this.props.onChange && this.props.onChange( onChangeValue, e)
    }
  }

  setUpdatedValue(value, e) {
    const onChangeValue = this.props.label ? this.getValueObjectWithLabel(value) : value

    if( !this.props.updateOnAction ){
      this.props.onChange && this.props.onChange(onChangeValue, e)
    }
    this.setState({ value })
  }

  handleDrag = (e) => {
    if (this.props.dragLabel) {
      const newValue = Math.round(this.props.value + e.movementX)
      if (newValue >= 0 && newValue <= this.props.dragMax) {
        this.props.onChange && this.props.onChange(this.getValueObjectWithLabel(newValue), e)
      }
    }
  }

  handleMouseDown = (e) => {
    if (this.props.dragLabel) {
      e.preventDefault()
      this.handleDrag(e)
      window.addEventListener('mousemove', this.handleDrag)
      window.addEventListener('mouseup', this.handleMouseUp)
    }
  }

  handleMouseUp = () => {
    this.unbindEventListeners()
  }

  unbindEventListeners = () => {
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  render() {
    const styles = reactCSS({
      'default': {
        wrap: {
          position: 'relative',
        },
      },
      'user-override': {
        wrap: this.props.style && this.props.style.wrap ? this.props.style.wrap : {},
        input: this.props.style && this.props.style.input ? this.props.style.input : {},
        label: this.props.style && this.props.style.label ? this.props.style.label : {},
      },
      'dragLabel-true': {
        label: {
          cursor: 'ew-resize',
        },
      },
    }, {
      'user-override': true,
    }, this.props)

    return (
      <div style={ styles.wrap }>
        <input
          className="input-field"
          id={ this.inputId }
          style={ styles.input }
          ref={ input => this.input = input }
          value={ this.props.type == 'alpha' ? this.state.value+'%' : this.state.value }
          onKeyDown={ (e) => this.handleKeyDown(e) }
          onClick={ (e) => this.handleClick(e) }
          onChange={ (e) => this.handleChange(e) }
          onBlur={ (e) => this.handleBlur(e) }
          placeholder={ this.props.placeholder }
          spellCheck="false"
          autoComplete="off"
          autoFocus={this.props.focusOnInit ? true : false }
        />
      </div>
    )
  }
}

export default EditableInput
