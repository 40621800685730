import React from 'react'
import { ReactCSS as reactCSS } from 'reactcss'
import { handleFocus } from '../../helpers/interaction'

import Checkboard from './Checkboard'

const ENTER = 13

export const Swatch = ({ color, style, onClick = () => {}, onMouseDown = () => {}, onHover, title = color,
  children, focus, focusStyle = {}, pseudoFocus }) => {
  const transparent = color === 'transparent'
  const styles = reactCSS({
    default: {
      swatch: {
        background: color,
        ...style,
        ...(pseudoFocus ? focusStyle : {}),
      },
    },
  })

  const handleClick = e => onClick(color, e)
  const handleKeyDown = e => e.keyCode === ENTER && onClick(color, e)
  const handleHover = e => onHover(color, e)
  const handlePointerDown = e => onMouseDown(color, e)

  const optionalEvents = {}
  if (onHover) {
    optionalEvents.onMouseOver = handleHover
  }

  return (
    <div
      style={ styles.swatch }
      className="swatch checkboard"
      onClick={ handleClick }
      onMouseDown={ handlePointerDown }
      title={ title }
      tabIndex={ 0 }
      onKeyDown={ handleKeyDown }
      { ...optionalEvents }
    >
      { children }
      {/* { transparent && ( */}
      {/*   <Checkboard */}
      {/*     borderRadius={ styles.swatch.borderRadius } */}
      {/*     boxShadow="inset 0 0 0 1px rgba(0,0,0,0.1)" */}
      {/*   /> */}
      {/* ) } */}
    </div>
  )
}

export default handleFocus(Swatch)
